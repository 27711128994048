import { defineStore } from "pinia";

export const useAuth = defineStore(
  "riot-realms-auth",
  () => {
    const access = ref("");
    const accessExpired = ref(null);
    const refresh = ref("");
    const refreshExpired = ref(null);

    // для удобства отладки - сохраняем в стор
    watch(access, () => {
      if (!access.value) return;
      const { exp } = tokenDecode(access.value);
      accessExpired.value = new Date(exp * 1000);
    });
    watch(refresh, () => {
      if (!refresh.value) return;
      const { exp } = tokenDecode(refresh.value);
      refreshExpired.value = new Date(exp * 1000);
    });

    const loggedIn = computed(() => !!access.value);

    const $reset = () => {
      access.value = "";
      accessExpired.value = null;
      refresh.value = "";
      refreshExpired.value = null;
    };

    const tokenDecode = (token) => {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      return JSON.parse(jsonPayload);
    };

    const accessTokenExpired = () => {
      const { exp } = tokenDecode(access.value);
      return Date.now() >= exp * 1000;
    };

    return {
      access,
      accessExpired,
      refresh,
      refreshExpired,
      loggedIn,
      $reset,
      accessTokenExpired,
    };
  },
  {
    // modules: "pinia-plugin-persistedstate/nuxt",
    persist: {
      storage: localStorage,
    },
  },
);
