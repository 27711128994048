const commonDicts = [
  "department", // user.department; leaderboard.department;
  "departments", // office.departments
  "game", // gamemode.game; gamerole.game; gameserver.game; parties.game;
  "games", // user.games; leaderboard.games;
  "mode", // gamemode.mode; parties.mode;
  "modes", // game.modes
  "office", // user.office; leaderboard.office;
  "role", // gamerole.role;
  "roles", // game.roles;
  "server", // gameserver.server; account.server;
  "servers", // game.servers;
  "slots", // user.slots;
];
const dictMapping = {
  game_modes: "gamemodes", // user.game_modes
  player_types: "playertypes", // user.player_types
  primary_roles: "gameroles", // user.primary_roles
  secondary_roles: "gameroles", // user.secondary_roles
  game_servers: "gameservers", // user.game_servers
};

export default (target, source) => {
  const _passThrough = (value, k = null) => {
    if (
      typeof value === "number" &&
      (commonDicts.includes(k) || useHas(dictMapping, k))
    ) {
      // change id -> Object
      let key = useGet(dictMapping, k, k);
      key = useTrimEnd(key, "s") + "s"; // example: role & roles => roles
      const res = useFind(source[key], { id: value });
      return res || value;
    }

    if (Array.isArray(value)) {
      return value.map((item) => _passThrough(item, k));
    }

    if (typeof value === "object" && value !== null) {
      const newObj = {};
      for (const key in value) {
        newObj[key] = _passThrough(value[key], key);
      }
      return newObj;
    }

    return value;
  };
  return _passThrough(target);
};
