/**
 * Plugin replaces <a> to <NuxtLink> in v-nuxt-html directive.
 *
 *  const htmlString = ref('<a href="/example-1">Example 1</a>');
 * <div v-nuxt-html="htmlString"></div>
 *
 * https://www.trpkovski.com/2024/03/24/is-it-possible-to-use-nuxt-link-in-content-rendered-with-v-html
 */
const idsWithListeners = new Set<string>();

const assignAnchorsIds = (html: string) => {
  const domParser = new DOMParser();
  const doc = domParser.parseFromString(html, "text/html");
  const anchors = doc.querySelectorAll("a");

  anchors.forEach((anchor) => {
    if (!anchor.hasAttribute("id")) {
      anchor.setAttribute("id", uuid());
    }
  });
  return doc.documentElement.outerHTML;
};

const convertAnchorToNuxtLink = (html: HTMLElement) => {
  const anchors = html.querySelectorAll("a");

  anchors.forEach((anchor) => {
    if (anchor.id && !idsWithListeners.has(anchor.id)) {
      const link = document.getElementById(anchor.id);

      link?.addEventListener("click", (event) => {
        event.preventDefault();
        const to = anchor.getAttribute("href");
        if (to) {
          useNuxtApp().$router.push(to);
        }
      });

      // Add the ID to the Set
      idsWithListeners.add(anchor.id);
    }
  });
};

const removeListeners = () => {
  idsWithListeners.forEach((id) => {
    const link = document.getElementById(id);
    link?.removeEventListener("click", () => {});
    idsWithListeners.delete(id);
  });
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("nuxtHtml", {
    mounted(el: HTMLElement, binding) {
      el.innerHTML = assignAnchorsIds(binding.value);
      convertAnchorToNuxtLink(el);
    },
    updated(el, binding) {
      el.innerHTML = assignAnchorsIds(binding.value);
      convertAnchorToNuxtLink(el);
    },
    unmounted() {
      removeListeners();
    },
  });
});
