import { defineNuxtPlugin, addRouteMiddleware, useRuntimeConfig } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  const apiBase = useRuntimeConfig().public.apiBase;
  const config = useRuntimeConfig().public.nuxtJwtAuth;

  const auth = useAuth();
  const { access, refresh } = storeToRefs(auth);

  addRouteMiddleware("auth", () => {
    if (!access.value) {
      return config.redirects.login;
    }
  });

  /**
   * Авторизация по логину/паролю. Используется для локальной разработки,
   * чтоб не настраивать на машинах разработчиков SSL-сертификаты для доменов riotgames.com
   * @param credentials: {login:"", password: ""}
   * @param callback: function
   */
  const login = async (credentials, callback?) => {
    const fetch = $fetch.create({ baseURL: apiBase });
    try {
      const response = await fetch(config.endpoints.login, {
        method: "POST",
        body: credentials,
      });

      if (response?.access) {
        access.value = response.access;
        refresh.value = response.refresh;
      }

      if (callback !== undefined) {
        callback(response);
        return;
      }
      window.location.replace(config.redirects.home);
    } catch (error: any) {
      throw error.data;
    }
  };

  /**
   * Get start url link to begin Okta auth.
   */
  const getOktaUrl = async () => {
    const fetch = $fetch.create({ baseURL: apiBase });
    // eslint-disable-next-line
    const { redirect_uri } = await fetch(config.endpoints.oktaStart, {
      method: "POST",
    });
    // eslint-disable-next-line
    return redirect_uri;
  };

  /**
   * Auth by Okta SSO.
   * @param code: string – Okta return code
   */
  const loginOkta = async (code) => {
    const fetch = $fetch.create({ baseURL: apiBase });
    try {
      const response = await fetch(config.endpoints.loginOkta, {
        method: "POST",
        body: {
          code,
          provider: config.oauth.provider,
          redirect_uri: config.oauth.redirectUri,
        },
      });
      if (response?.token) {
        access.value = response.token;
        refresh.value = response.refresh;
      }
      window.location.replace(config.redirects.home);
    } catch (error: any) {
      throw error.data;
    }
  };

  const refreshToken = async () => {
    // eslint-disable-next-line
    console.log("Trying refresh Access Token...");
    const fetch = $fetch.create({
      baseURL: apiBase,
      async onResponseError({ response }) {
        if (response.status >= 400) {
          auth.$reset();
          await navigateTo({ name: "login" });
        }
      },
    });
    const { data: response } = await useAsyncData(
      "token-refresh",
      () =>
        fetch(config.endpoints.refresh, {
          method: "POST",
          body: { refresh: refresh.value },
        }),
      {
        dedupe: "defer",
      },
    );
    if (response.value?.access) {
      access.value = response.value.access;
    }
  };

  const logout = () => {
    auth.$reset();
    window.location.replace(config.redirects.logout);
  };

  return {
    provide: {
      jwtAuth: {
        login,
        loginOkta,
        logout,
        refreshToken,
        getOktaUrl,
      },
    },
  };
});
