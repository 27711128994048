export default defineNuxtPlugin((nuxtApp) => {
  const auth = useAuth();
  const { access } = storeToRefs(auth);
  const { public: config } = nuxtApp.$config;

  const api = $fetch.create({
    baseURL: config.apiBase,
    async onRequest({ request, options }) {
      if (auth.accessTokenExpired()) {
        // eslint-disable-next-line
        console.log("Access Token has expired", request);
        await nuxtApp.$jwtAuth.refreshToken();
      } else {
        // eslint-disable-next-line
        console.log("Access Token is actual", request);
      }

      if (access.value) {
        const headers = (options.headers ||= {});
        if (Array.isArray(headers)) {
          headers.push(["Authorization", `Bearer ${access.value}`]);
        } else if (headers instanceof Headers) {
          headers.set("Authorization", `Bearer ${access.value}`);
        } else {
          headers.Authorization = `Bearer ${access.value}`;
        }
      }
    },
    async onResponseError({ response, error }) {
      if (response.status === 401) {
        await nuxtApp.runWithContext(
          async () => await navigateTo({ name: "login" }),
        );
      } else if (response.status === 404) {
        throw showError({
          statusCode: response.status,
          statusMessage: response.statusText,
          data: response._data,
        });
      } else {
        throw error;
      }
    },
  });

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  };
});
