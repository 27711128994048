import revive_payload_client_COX561wYlb from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_juymVZdIvy from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_u0xpBY94sQ from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WrbGkeve8p from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_05vCF2etaD from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yVK6i15wp7 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Etf2sa0TWJ from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WuXOnYTfYx from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_@rollup+wasm-node@4.24.4_magicast@0.3.5_typescript@5.5.3_vue@3.4.38_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_R4zk5iEQbg from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_eslint@8.57.0_i_dy6m5sx4sah4vsqvwkfv4i6fmi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_3RVO0KEiWB from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_@rollup+wasm-node@4.24.4_axios@1.7.5_magicast@0.3.5_vite@5.4.2_@types+node@22_76zf3zmwq6nvaj2f6rxt6hwlzm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Wwvvp7y2O9 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_@rollup+wasm-node@4.24.4_axios@1.7.5_magicast@0.3.5_vite@5.4.2_@types+node@22_76zf3zmwq6nvaj2f6rxt6hwlzm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_O3rPenycii from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_@rollup+wasm-node@4.24.4_axios@1.7.5_magicast@0.3.5_vite@5.4.2_@types+node@22_76zf3zmwq6nvaj2f6rxt6hwlzm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ytSTaWPDzJ from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_@rollup+wasm-node@4.24.4_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_79fHrk6unO from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_@rollup+wasm-node@4.24.4_magicast@0.3.5_vite@5.4.2_@types+node@22.5.1_sass-e_ndcidjc556tw7vzxvfxeja3cim/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_EBSFlO3XDG from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@rollup+wasm-node@4.24.4_@types+node@22.5.1_@vue+t_tsg3v363okjhyo744k2hm25x3e/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_c1qEM64Us5 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_@rollup+wasm-node@4.24.4_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_1NUFkqT2BG from "/app/node_modules/.pnpm/nuxt-viewport@2.1.5_@rollup+wasm-node@4.24.4_magicast@0.3.5_vue@3.4.38_typescript@5.5.3__webpack-sources@3.2.3/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_xzDxvasbIE from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.0.1_@pinia+nuxt@0.5.4_@rollup+wasm-node@4.24.4_magicast@0.3.5_t_6e6hdj3ec7wejkv4enlzbzxsje/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import sentry_client_Xh7FRGJeH8 from "/app/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._6mgbglwqw4ozxu4sstq4ynxmn4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import nuxt_html_XNiEKkzXCX from "/app/plugins/nuxt-html.ts";
export default [
  revive_payload_client_COX561wYlb,
  unhead_juymVZdIvy,
  router_u0xpBY94sQ,
  payload_client_WrbGkeve8p,
  navigation_repaint_client_05vCF2etaD,
  check_outdated_build_client_yVK6i15wp7,
  chunk_reload_client_Etf2sa0TWJ,
  plugin_vue3_WuXOnYTfYx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_R4zk5iEQbg,
  slideovers_3RVO0KEiWB,
  modals_Wwvvp7y2O9,
  colors_O3rPenycii,
  plugin_client_ytSTaWPDzJ,
  plugin_79fHrk6unO,
  plugin_EBSFlO3XDG,
  plugin_c1qEM64Us5,
  plugin_client_1NUFkqT2BG,
  plugin_xzDxvasbIE,
  sentry_client_Xh7FRGJeH8,
  sentry_client_config_o34nk2sJbg,
  auth_vT9JWWT9pN,
  api_GFfDXud5Cr,
  nuxt_html_XNiEKkzXCX
]