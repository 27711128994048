import { defineStore } from "pinia";
/**
 * Common dictionaries.
 */
export const useDictionaries = defineStore("dictionaries", () => {
  const { data, execute, refresh } = useAPI("/profiles/data/", {
    // двойная трансформация из-за ссылки source на себя (обогащение данных на основе себя)
    transform: (source) => enrich(enrich(source, source), source),
  });

  // append first/default value for filter reset
  const appendAnyItem = (list) => {
    list?.unshift({ id: "", slug: "any", name: "Any" });
    return list;
  };

  return {
    // state
    dictionaries: data,

    // getters
    playertypes: computed(() => appendAnyItem(data.value?.playertypes)),
    games: computed(() => data.value?.games),
    modes: computed(() => data.value?.modes),
    roles: computed(() => data.value?.roles),
    servers: computed(() => data.value?.servers),
    gamemodes: computed(() => data.value?.gamemodes),
    gameroles: computed(() => data.value?.gameroles),
    gameservers: computed(() => data.value?.gameservers),
    offices: computed(() => appendAnyItem(data.value?.offices)),
    departments: computed(() => appendAnyItem(data.value?.departments)),
    slots: computed(() => data.value?.slots),
    tiers: computed(() => data.value?.tiers), // progress bar points
    slotsWeekdays: computed(() =>
      data.value?.slots.reduce((acc, slot) => {
        if (!acc.includes(slot.weekday)) {
          acc.push(slot.weekday);
        }

        return acc;
      }, []),
    ),

    // actions
    execute,
    refresh,
  };
});
