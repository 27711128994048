const _black = "#333"
const _white = "#FFFFFF"
const _logo = "#383593"
const _transparent = "transparent"
const _current = "currentColor"
const _orange = {"100":"#c8aa6e","200":"#ff9e00","250":"#FFB60C","300":"#d59249"}
const _violet = {"50":"#F5E8F9","100":"#DEB9EE","200":"#CF97E5","300":"#B967D9","400":"#AB49D2","500":"#961CC7","600":"#8919B5","700":"#6B148D","800":"#530F6D","900":"#3F0C54"}
const _blue = {"50":"#E8ECF3","100":"#C7D1E1","200":"#9BADCA","300":"#6C87B1","400":"#406299","500":"#164083","600":"#13366F","700":"#102D5D","800":"#0D244B","900":"#0A1D3B","1300":"#32C7FF"}
const _navy = {"50":"#E6E8EF","100":"#C4C7D8","200":"#959BBA","300":"#646D9A","400":"#35417B","500":"#09175E","600":"#081450","700":"#061043","800":"#050D36","900":"#040A2A"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)","1100":"#0000000D","1200":"#E0E0E099","1300":"#E7E6E3","1400":"#D9D9D9","1500":"#BDBDBD","1525":"#8B978F","1550":"#8A9099","1600":"#7E7E7E","1700":"#5F5F5F","1750":"#5F5F5F99","1800":"#05050599","1900":"#ffffff99"}
const _red = {"50":"#FFEAE6","100":"#FFDFD9","200":"#FFBCB0","300":"#FF2800","350":"#EB0029","400":"#E62400","500":"#CC2000","600":"#BF1E00","700":"#991800","800":"#731200","900":"#590E00","1250":"#FF4655","1300":"#F04438"}
const _green = {"50":"#EBFCEC","100":"#E1FAE2","200":"#C0F5C3","300":"#35E03E","400":"#30CA38","500":"#2AB332","600":"#28A82F","700":"#208625","800":"#18651C","900":"#134E16","1500":"#8CD60B"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "black": _black, "white": _white, "logo": _logo, "transparent": _transparent, "current": _current, "orange": _orange, "violet": _violet, "blue": _blue, "navy": _navy, "gray": _gray, "red": _red, "green": _green, "primary": _primary, "cool": _cool, "black-off": "#050505", "white-off": "#F1F5F1",  }
export { config as default, _black, _white, _logo, _transparent, _current, _orange, _violet, _blue, _navy, _gray, _red, _green, _primary, _cool }