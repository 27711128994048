import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconArrowDown, LazyIconArrowRight, LazyIconArrowTop, LazyIconBell, LazyIconBurger, LazyIconCheckThin, LazyIconCheck, LazyIconChevronDown, LazyIconCloseThin, LazyIconClose, LazyIconCopy, LazyIconGoogleCalendar, LazyIconGoogleError, LazyIconGoogleSuccess, LazyIconLike, LazyIconLoader, LazyIconLogoMobile, LazyIconLogo, LazyIconLogout, LazyIconLol, LazyIconLor, LazyIconQuestion, LazyIconRankArrow, LazyIconRiotesBg, LazyIconTft, LazyIconUpload, LazyIconValorant, LazyIconWelcomeBg, LazyIconWildRift, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["IconArrowDown", LazyIconArrowDown],
["IconArrowRight", LazyIconArrowRight],
["IconArrowTop", LazyIconArrowTop],
["IconBell", LazyIconBell],
["IconBurger", LazyIconBurger],
["IconCheckThin", LazyIconCheckThin],
["IconCheck", LazyIconCheck],
["IconChevronDown", LazyIconChevronDown],
["IconCloseThin", LazyIconCloseThin],
["IconClose", LazyIconClose],
["IconCopy", LazyIconCopy],
["IconGoogleCalendar", LazyIconGoogleCalendar],
["IconGoogleError", LazyIconGoogleError],
["IconGoogleSuccess", LazyIconGoogleSuccess],
["IconLike", LazyIconLike],
["IconLoader", LazyIconLoader],
["IconLogoMobile", LazyIconLogoMobile],
["IconLogo", LazyIconLogo],
["IconLogout", LazyIconLogout],
["IconLol", LazyIconLol],
["IconLor", LazyIconLor],
["IconQuestion", LazyIconQuestion],
["IconRankArrow", LazyIconRankArrow],
["IconRiotesBg", LazyIconRiotesBg],
["IconTft", LazyIconTft],
["IconUpload", LazyIconUpload],
["IconValorant", LazyIconValorant],
["IconWelcomeBg", LazyIconWelcomeBg],
["IconWildRift", LazyIconWildRift],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
