import type { PluginAPI } from "tailwindcss/types/config";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// Генерируем utils для работы с отступами (читай Readme)

const utilityConfigurations = [
  { property: "margin", prefix: "m" },
  { property: "marginTop", prefix: "mt" },
  { property: "marginRight", prefix: "mr" },
  { property: "marginBottom", prefix: "mb" },
  { property: "marginLeft", prefix: "ml" },
  { property: ["marginLeft", "marginRight"], prefix: "mx" },
  { property: ["marginTop", "marginBottom"], prefix: "my" },
  { property: "padding", prefix: "p" },
  { property: "paddingTop", prefix: "pt" },
  { property: "paddingRight", prefix: "pr" },
  { property: "paddingBottom", prefix: "pb" },
  { property: "paddingLeft", prefix: "pl" },
  { property: ["paddingLeft", "paddingRight"], prefix: "px" },
  { property: ["paddingTop", "paddingBottom"], prefix: "py" },
  { property: "gap", prefix: "gap" },
  { property: "rowGap", prefix: "gap-y" },
  { property: "columnGap", prefix: "gap-x" },
];

const autoUtilities = {
  ".m-auto": { margin: "auto" },
  ".mx-auto": { marginLeft: "auto", marginRight: "auto" },
  ".my-auto": { marginTop: "auto", marginBottom: "auto" },
  ".mt-auto": { marginTop: "auto" },
  ".mr-auto": { marginRight: "auto" },
  ".mb-auto": { marginBottom: "auto" },
  ".ml-auto": { marginLeft: "auto" },
};

const step = 4;
const maxIndex = 100;

const generateSpaceUtilities = () => {
  const utilities = {};

  for (let i = 0, s = 0; i <= maxIndex; i++, s += step) {
    utilities[`.space-x-${i} > * + *`] = { marginLeft: `${s}px` };
    utilities[`.space-y-${i} > * + *`] = { marginTop: `${s}px` };
  }

  return utilities;
};

export const generateMainUtilities = () => {
  const utilities = {};

  const parsePropsToObject = (prePrefix, { property, prefix }) => {
    if (Array.isArray(property)) {
      for (let i = 0, s = 0; i <= maxIndex; i++, s += step) {
        utilities[`.${prePrefix}${prefix}-${i}`] = Object.fromEntries(
          property.map((prop) => [prop, `${prePrefix}${s}px`]),
        );
      }
    } else {
      for (let i = 0, s = 0; i <= maxIndex; i++, s += step) {
        utilities[`.${prePrefix}${prefix}-${i}`] = {
          [property]: `${prePrefix}${s}px`,
        };
      }
    }
  };

  utilityConfigurations.forEach(({ property, prefix }) => {
    // создаем отрицательные margins
    if (prefix.startsWith("m")) {
      parsePropsToObject("-", { property, prefix });
    }

    // создаем все остальные отступы
    parsePropsToObject("", { property, prefix });
  });

  return utilities;
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export default (plugin: PluginAPI) => {
  plugin.addUtilities(autoUtilities);

  plugin.addUtilities(generateSpaceUtilities());

  plugin.addUtilities(generateMainUtilities());
};
